import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import Messages from "../reusable-components/Messages";
import LoaderComponent from "../reusable-components/LoaderComponent";
import ConfirmationModal from "../reusable-components/ConfirmationModal";
import { submitSelfSurvey } from "../../services/surveyServices";
import "./styles.scss";

const { surveyQuestions } = require("./data.json");

const SelfSurvey = ({ preSelfSurvey = "false", surveyStatus }) => {
  const { t } = useTranslation();
  const [studentSurvey, setStudentSurvey] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // with add & remove filter
  const CheckHandler = (e, idx, type) => {
    let filterList = [],
      survey;
    type == "radio"
      ? (survey = {
          question_id: +e.target.id,
          answer_id: +idx,
        })
      : (survey = {
          question_id: +e.target.id,
          answer_id: e.target.value.trim(),
        });
    filterList = studentSurvey?.filter((item) => {
      return item["question_id"] !== +e.target.id;
    });
    survey?.answer_id?.length !== 0 && filterList.push(survey);
    setStudentSurvey(filterList);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const answers = {
      answers: studentSurvey,
    };
    const sendSurvey = await submitSelfSurvey(answers);
    if (sendSurvey.success == true) {
      setTimeout(() => {
        surveyStatus(true);
      }, 3000);
    } else setIsSubmitting(false);
  };
  if (!surveyQuestions.preSurveyQuestions.length) return <LoaderComponent />;

  return (
    <ConfirmationModal
      size="lg"
      show={true}
      confirm={null}
      closebutton={"false"}
      // ok={t("confirmation-message.yes-sure")}
      // cancel={t("confirmation-message.continue")}
      // addedClasses="exitBtn"
      // type="exitTest"
    >
      <div className="self-survey">
        <div className="row justify-content-center py-4 ">
          {/* Questions Answer [Parent Holder] */}
          <div className="self-survey__holder col-lg-11 px-3 px-lg-4 englease__bg--white englease__radius--f-4">
            {/* Questions Title And Subtitle [Section] */}
            <div className="py-4 text-center">
              <p className="englease__txt--main englease englease--bold englease--f-24">
                {t(
                  `self-survey.${
                    preSelfSurvey == "true" ? "pre" : "post"
                  }-title`
                )}
              </p>
              {/* <p className="m-0 englease__txt--muteTxt englease englease--f-16">
								{t("self-survey.sub-title")}
							</p> */}
            </div>
            {/* Questions And Answers [Section] */}
            <div className="self-survey__section w-100">
              {/* <p className="m-0 englease__txt--black englease englease--bold englease--f-18 pb-3">
								{t("self-survey.hint")}
							</p> */}
              <div className="d-flex flex-column align-items-between">
                <Messages
                  status={false}
                  flag="iET-expired-warning"
                  classType={"survey"}
                >
                  {<span>{t("self-survey.required-msg")} </span>}
                </Messages>
              </div>
              <div className="d-flex flex-column align-items-between">
                <ol className="d-flex flex-column gap-3">
                  {(preSelfSurvey == "true"
                    ? surveyQuestions?.preSurveyQuestions
                    : surveyQuestions?.postSurveyQuestions
                  )?.map((question, index) => {
                    return (
                      <li key={index} className="">
                        <p className="englease__txt--black englease englease--bold englease--f-16">
                          {question?.title}
                        </p>
                        <div className="row justify-content-center text-center mb-4">
                          {question?.answers?.map((answer, idx) => {
                            return (
                              <div
                                key={idx}
                                onChange={(e) =>
                                  CheckHandler(e, answer.id, "radio")
                                }
                                className={`col-4 col-md-2 py-2 d-flex flex-column gap-4 englease englease--f-16`}
                                // style={{ color: `${answer.color}` }}
                              >
                                <input
                                  className="englease--click"
                                  id={question?.id}
                                  type="radio"
                                  name={question?.id}
                                  defaultValue={answer?.id}
                                />
                                {answer?.value}
                              </div>
                            );
                          })}
                        </div>
                      </li>
                    );
                  })}
                  {preSelfSurvey !== "true" && (
                    <>
                      <li className="">
                        <label
                          htmlFor="44"
                          className="form-label englease__txt--black englease englease--bold englease--f-16"
                        >
                          In your opinion, how will this course help you in your
                          career?
                        </label>
                        <textarea
                          id="44"
                          required
                          className="form-control"
                          onBlur={(e) => CheckHandler(e, 44, "text")}
                        ></textarea>
                      </li>
                      <li>
                        <label
                          htmlFor="45"
                          className="form-label englease__txt--black englease englease--bold englease--f-16"
                        >
                          Do you have any additional comments related to your
                          experience taking this course?
                        </label>
                        <textarea
                          id="45"
                          required
                          className="form-control"
                          onBlur={(e) => CheckHandler(e, 45, "text")}
                        ></textarea>
                      </li>
                    </>
                  )}
                </ol>

                <div className=" d-flex justify-content-center align-items-center mt-auto">
                  <Button
                    onClick={handleSubmit}
                    className={`${
                      (preSelfSurvey == "true"
                        ? surveyQuestions?.preSurveyQuestions?.length
                        : surveyQuestions?.postSurveyQuestions?.length + 2) ===
                        studentSurvey?.length && isSubmitting === false
                        ? "englease--click"
                        : "englease--unClick"
                    } self-survey__button px-4 py-2  englease__border--main englease__bg--main englease__txt--white englease__radius--f-8  englease englease--f-14`}
                    disabled={
                      (preSelfSurvey == "true"
                        ? surveyQuestions?.preSurveyQuestions?.length
                        : surveyQuestions?.postSurveyQuestions?.length + 2) ===
                        studentSurvey?.length && isSubmitting === false
                        ? false
                        : true
                    }
                  >
                    <span className="englease englease--f-14">
                      {t("self-survey.submit")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfirmationModal>
  );
};
export default SelfSurvey;
