import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const CountDownTimer = ({ timer, getTimeStatus, setCountDownEnded, recordedClass }) => {
	const { t } = useTranslation();
	let counter = 1;

	const [countdownDate, setCountdownDate] = useState(Number(timer));
	const [state, setState] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	useEffect(() => {
		//return if class is of pre-recorded type
		if (recordedClass === true) {getTimeStatus(false); setCountDownEnded(true); return;}

		const timer = setInterval(() => setNewTime(), 1000);
		return () => {
			clearInterval(timer);
		};
	}, []);

	const setNewTime = () => {
		if (countdownDate) {
			let distanceToDate = countdownDate - 1000 * counter;

			let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
			let hours = Math.floor(
				(distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			let minutes = Math.floor(
				(distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
			);
			let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

			const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

			days = `${days}`;
			if (numbersToAddZeroTo.includes(hours)) {
				hours = `0${hours}`;
			} else if (numbersToAddZeroTo.includes(minutes)) {
				minutes = `0${minutes}`;
			} else if (numbersToAddZeroTo.includes(seconds)) {
				seconds = `0${seconds}`;
			}

			if (distanceToDate >= 0) {
				setState({ days: days, hours: hours, minutes, seconds });
				counter += 1;
				getTimeStatus(true);
			}
			if (distanceToDate <= 600000) getTimeStatus(false);
			if (distanceToDate <= 0) setCountDownEnded(true);
		}
	};

	return (
		<div className="countdown px-4 py-1 englease--f-12 englease__bg--white">
			<div className="d-flex flex-column justify-content-center align-items-center">
				<p className="mb-0">{t("classes-list.class-details.countdown.day")}</p>
				<p className="mb-0 englease--bold course-time-day">
					{state.days || "0"}
				</p>
			</div>
			{/* Divider  */}
			<div className="countdown--divider"></div>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<p className="mb-0">{t("classes-list.class-details.countdown.hrs")}</p>
				<p className="mb-0 englease--bold course-time-hour">
					{state.hours || "0"}
				</p>
			</div>
			{/* Divider  */}
			<div className="countdown--divider"></div>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<p className="mb-0">{t("classes-list.class-details.countdown.mins")}</p>
				<p className="mb-0 englease--bold course-time-minute">
					{state.minutes || "0"}
				</p>
			</div>
			{/* Divider  */}
			<div className="countdown--divider"></div>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<p className="mb-0">{t("classes-list.class-details.countdown.secs")}</p>
				<p className="mb-0 englease--bold course-time-second">
					{state.seconds || "0"}
				</p>
			</div>
		</div>
	);
};

export default CountDownTimer;
